import { useLocation, useNavigate } from '@remix-run/react'
import { useGateValue } from '@statsig/react-bindings'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu'
import { Label } from '#app/components/ui/label'
import { Switch } from '#app/components/ui/switch'
import { toast } from '#app/components/ui/use-toast'
import { type loader as getJWTLoader } from '#app/routes/resources+/eng+/get-jwt'
import { useInternalView } from '#app/utils/providers/useInternalView'
import { type UserConfidential } from '#types/api-client.types.gen'

type ProfileMenuProps = {
	user?: UserConfidential
	userPicture?: string
}

export default function ProfileMenu(props: ProfileMenuProps) {
	const navigate = useNavigate()
	const location = useLocation()
	const gateEng = useGateValue('fe-eng')

	const { toggleView, isInternalView } = useInternalView()

	const engCopyJWTToken = () => {
		const fetchToken = async () => {
			try {
				const response = await fetch('/resources/eng/get-jwt')
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`)
				}
				const result = (await response.json()) as Awaited<
					ReturnType<typeof getJWTLoader>
				>

				// copy the candidate id to the clipboard
				if (
					result.ok &&
					'token' in result &&
					typeof result.token === 'string'
				) {
					navigator.clipboard
						.writeText(result.token)
						.then(() => {
							// show a toast
							toast({
								title: 'Copied to clipboard',
								description: `JWT Token copied to the clipboard successfully!`,
								variant: 'success',
							})
						})
						.catch((err) => {
							// show a toast
							toast({
								title: 'Error',
								description: `Failed to copy JWT Token to the clipboard...`,
								variant: 'destructive',
							})
							console.error('Error copying JWT:', err)
						})
				} else {
					throw new Error('Failed to fetch JWT token')
				}
			} catch (e) {
				console.warn('Error fetching JWT:', e)
				toast({
					title: 'Error',
					description: `Error fetching JWT token...`,
					variant: 'destructive',
				})
			}
		}

		fetchToken().catch(() => {})
	}

	// are we in the admin section?
	// path starts with /admin
	const isAdminApp = location.pathname.startsWith('/admin')
	const isOrgApp = location.pathname.startsWith('/org')

	return (
		<DropdownMenu>
			<DropdownMenuTrigger className="flex items-center rounded-full border-2 bg-white text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
				<img
					className="h-9 w-9 rounded-full"
					src={props.userPicture ?? ''}
					alt=""
				/>
			</DropdownMenuTrigger>
			<DropdownMenuContent>
				<DropdownMenuLabel>
					{props.user?.name ?? 'My Account'}
				</DropdownMenuLabel>
				{props.user?.is_admin && props.user.is_admin === true && (
					<>
						<DropdownMenuSeparator />
						{!isAdminApp ? (
							<>
								<DropdownMenuItem
									onClick={() => navigate('/admin')}
								>
									Admin
								</DropdownMenuItem>
							</>
						) : null}
						{!isOrgApp && (
							<DropdownMenuItem onClick={() => navigate('/org')}>
								App
							</DropdownMenuItem>
						)}
						<div className="flex items-center space-x-2 p-2">
							<Switch
								id="toggle-view"
								checked={!isInternalView}
								onCheckedChange={toggleView}
							/>
							<Label
								htmlFor="toggle-view"
								className="font-normal"
							>
								Client View
							</Label>
						</div>
					</>
				)}
				<DropdownMenuSeparator />
				{gateEng && (
					<>
						<DropdownMenuItem onClick={engCopyJWTToken}>
							Copy JWT Token
						</DropdownMenuItem>
						<DropdownMenuSeparator />
					</>
				)}
				<DropdownMenuItem onClick={() => navigate('/logout')}>
					Sign out
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
